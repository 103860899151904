@charset "utf-8";

/* =====================================================
フッター
===================================================== */
#footer{
  font-family: $base-gothic-font;
  background-color: #F7F7F7;
  @include props(pt, 70);
  @include props(pb, 30);

  @include mq(md) {
    @include props(pt, 70, 750);
    @include props(pb, 50, 750);
  }

  .container{
    max-width: 1406px;

    @include mq(md) {
      padding: 0 40px;
    }
  }

  .flex{
    max-width: 1130px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @include mq(md) {
      flex-wrap: wrap;
    }
  }

  .contentBox{
    max-width: 100%;
    width: 100%;

    @include mq(md) {
      &:nth-of-type(1){
        display: flex;
        flex-wrap: wrap;
        order: 2;
      }
      &:nth-of-type(2){
        order: 1;
      }
    }
  }

  .footerNav{
    @include fz_vw(20);
    font-weight: 400;
    line-height: 1;
    @include props(mb, 40);

    @include mq(tab) {
      @include fz_vw(24);
    }

    @include mq(md) {
      width: 100%;
      @include fz_vw(28);
      @include props(mb, 20, 750);
      order: 1;
    }
    
    li{
      @include props(mb, 30);

      @include mq(md) {
        @include props(mb, 50, 750);
      }
    }

    a{
      color: #231815;
    }
  }

  .reserveBtn{
    max-width: 150px;
    width: 100%;
    @include fz_vw(28);
    font-weight: 400;
    line-height: 1;
    text-align: center;
    @include props(mb, 70);

    @include mq(tab) {
      @include fz_vw(32);
    }

    @include mq(md) {
      @include fz_vw(30);
      @include props(mb, 40, 750);
      order: 3;
    }

    a{
      color: #231815;
      @include props(pt, 15);
      @include props(pb, 15);
      @include props(pr, 30);
      @include props(pl, 30);
      border: solid 1px #231815;

      @include mq(md) {
        @include props(pt, 15, 750);
        @include props(pb, 15, 750);
        @include props(pr, 30, 750);
        @include props(pl, 30, 750);
      }
    }
  }

  .snsList{
    display: flex;
    align-items: center;

    @include mq(md) {
      width: 100%;
      order: 2;
      @include props(mb, 50, 750);
    }

    li{
      max-width: 45px;
      @include props(wd, 45);
      @include props(mr, 20);

      @include mq(md) {
        max-width: 86px;
        @include props(wd, 43, 750);
        @include props(mr, 20, 750);
      }
    }

    .tel{
      max-width: 100%;
      width: auto;
      @include fz_vw(20);
      font-weight: 400;
      line-height: 1;

      a{
        display: flex;
        align-items: center;
        color: #231815;

        img{
          max-width: 45px;
          @include props(wd, 45);
          margin: 0;
          flex-shrink: 0;
        }

        span{
          @include props(pl, 10);
        }
      }

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        @include fz_vw(28);

        a{
          img{
            max-width: 86px;
            @include props(wd, 43, 750);
          }
          span{
            @include props(pl, 10, 750);
          }
        }
      }
    }
  }

  .founderMail{
    max-width: 590px;
    width: 100%;
    @include props(mb, 58);

    @include mq(md) {
      @include props(mb, 8, 750);
    }

    .letterTit{
      @include props(mb, 25);

      @include mq(md) {
        @include props(mb, 25, 750);
      }
    }

    .letterTxt{
      @include fz_vw(20);
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: normal;
      @include props(mb, 15);

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        @include fz_vw(28);
        @include props(mb, 15, 750);
      }  
    }

    #mc_embed_signup{
      form{
        padding: 0;
      }

      .mc-field-group{
        width: 76%;
        order: 1;

        label{
          display: none;
        }
        input{
          height: 52px;
          border-radius: 0;
          -webkit-appearance: none;

          @include mq(tab) {
            height: 47px;
          }

          @include mq(md) {
            height: 36px;
          } 
        }
      }

      .clear{
        width: 24%;
        order: 2;
      }

      .button{
        width: 100%;
        @include fz_vw(26);
        height: 52px;
        line-height: 52px;
        border-radius: 0;
        padding: 0;
        margin: 0;

        @include mq(tab) {
          height: 47px;
          line-height: 47px;
        }

        @include mq(md) {
          height: 36px;
          line-height: 36px;
        } 
      }
      div#mce-responses {
        //display: none;
        order: 3;
        margin: 0!important;
        padding: 0!important;
        width: 100%!important;
        .response {
          padding: 0!important;
          margin: 0!important;

          @include mq(md) {
            @include fz_vw(24);
          } 
        }
      }
    }

    #mc_embed_signup_scroll{
      display: flex;
      flex-wrap: wrap;
    }
  }

  .footerfoterInstagramTit{
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @include props(mb, 8);

    @include mq(md) {
      @include props(pt, 50, 750);
      @include props(mb, 12, 750);
    }

    .logo{
      @include props(wd, 36);
      @include props(mt, 11);
      @include props(mr, 8);

      @include mq(md) {
        @include props(wd, 50, 750);
        @include props(mt, 13, 750);
        @include props(mr, 8, 750);
      }
    }
    .tit{
      @include props(wd, 180);

      @include mq(md) {
        @include props(wd, 240, 750);
      }
    }
  }

  .foterInstagram{
    max-width: 590px;
    width: 100%;
    @include props(mb, 50);

    @include mq(md) {
      @include props(mb, 70, 750);
    }
  }

  .copyright{
    display: block;
    @include fz_vw(12);
    font-weight: 400;
    list-style: 1;
    text-align: right;

    @include mq(md) {
      text-align: center;
    }
  }
}

#de{
  #footer{
    @include mq(md) {
      @include props(pt, 20, 750);
    }

    .footerNav{
      @include fz_vw(22);
      
      @include mq(tab) {
        @include fz_vw(26);
      }
  
      @include mq(md) {
        @include fz_vw(30);
      }
    }
  
    .reserveBtn{
      @include fz_vw(30);
  
      @include mq(tab) {
        @include fz_vw(34);
      }
  
      @include mq(md) {
        @include fz_vw(32);
      }
    }
  
    .founderMail{
      .letterTxt{
        @include fz_vw(22);
  
        @include mq(tab) {
          @include fz_vw(26);
        }
  
        @include mq(md) {
          @include fz_vw(30);
        }  
      }
  
      #mc_embed_signup{
        .button{
          @include fz_vw(28);
        }
        div#mce-responses {
          .response {
            @include mq(md) {
              @include fz_vw(26);
            } 
          }
        }
      }
    }
  
    .copyright{
      @include fz_vw(14);
    }
  }
}

#en{
  #footer{
    @include mq(md) {
      @include props(pt, 20, 750);
    }
    
    .footerNav{
      @include fz_vw(22);
      
      @include mq(tab) {
        @include fz_vw(26);
      }
  
      @include mq(md) {
        @include fz_vw(30);
      }
    }
  
    .reserveBtn{
      @include fz_vw(30);
  
      @include mq(tab) {
        @include fz_vw(34);
      }
  
      @include mq(md) {
        @include fz_vw(32);
      }
    }
  
    .founderMail{
      .letterTxt{
        @include fz_vw(22);
  
        @include mq(tab) {
          @include fz_vw(26);
        }
  
        @include mq(md) {
          @include fz_vw(30);
        }  
      }
  
      #mc_embed_signup{
        .button{
          @include fz_vw(28);
        }
        div#mce-responses {
          .response {
            @include mq(md) {
              @include fz_vw(26);
            } 
          }
        }
      }
    }
  
    .copyright{
      @include fz_vw(14);
    }
  }
}
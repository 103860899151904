@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#topics {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }

  .topicsList{
    .flex {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
  
      @include mq(md) {
        flex-wrap: wrap;
      }
    }
  
    .topicsCard {
      max-width: 30.61%;
      width: 100%;
      margin-right: 4.085%;
      @include props(mb, 70);
  
      &:nth-of-type(3n) {
        margin-right: 0;
      }
  
      .img{
        max-width: 100%;
        height: 173px;
        overflow: hidden;
        position: relative;
      }
  
      img {
        width: 100%;
        height: 173px;
        object-fit: cover;
        object-position: center;
      }
  
      .topicsCardTit {
        font-size: 1.6rem;
        line-height: 2;
        letter-spacing: normal;
        word-wrap : break-word;
        overflow-wrap : break-word;
        padding: 20px 7px 0;
      }
  
      @include mq(md) {
        max-width: 100%;
        margin-right: 0;
        @include props(mb, 70, 750);
  
        .img{
          max-width: 100%;
          height: auto;
          overflow: hidden;
          position: relative;
        }
  
        img {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
          @include props(he, 425, 750);
        }
  
        .topicsCardTit {
          @include fz_vw(32);
          line-height: 1.8;
          @include props(pt, 20, 750);
        }
      }
    }
  }

  .listPagerNumber{
    display: flex;
    align-items: center;
    justify-content: center;
    @include props(mt, 40);

    @include mq(md) {
      @include props(mt, 70, 750);
    }

    .number{
      font-family: $base-gothic-font;
      font-size: 1.6rem;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      @include props(mr, 20);
      @include props(ml, 20);

      a{
        color: #555;
      }

      @include mq(tab) {
        @include props(mr, 20, 750);
        @include props(ml, 20, 750);
      }

      @include mq(md) {
        @include fz_vw(30);
        @include props(mr, 30, 750);
        @include props(ml, 30, 750);
      }

      &.active{
        font-weight: bold;

        a{
          color: #000;
        }
      }

      &.prev{
        @include props(mr, 30);
        margin-left: 0;

        @include mq(tab) {
          @include props(mr, 30, 750);
        }
        @include mq(md) {
          @include props(mr, 40, 750);
        }
      }
      &.next{
        @include props(ml, 30);
        margin-right: 0;

        @include mq(tab) {
          @include props(ml, 30, 750);
        }
        @include mq(md) {
          @include props(ml, 40, 750);
        }
      }
    }
  }

  .topicsArticle{
    @include props(pt, 200);

    @include mq(md) {
      @include props(pt, 160, 750);
    }

    .container{
      max-width: 757px;
    }

    img{
      margin: 0 auto;
      @include props(mb, 95);
    }

    .topicsArticleTit{
      font-size: 2.6rem;
      line-height: 1.875;
      letter-spacing: normal;
      word-wrap : break-word;
      overflow-wrap : break-word;
      @include props(mb, 85);

      @include mq(md) {
        @include fz_vw(40);
      }
    }

    p{
      font-size: 1.6rem;
      line-height: 1.875;
      letter-spacing: normal;
      word-wrap : break-word;
      overflow-wrap : break-word;

      @include mq(md) {
        @include fz_vw(30);
      }
    }

    a{
      display: inline;
      text-decoration: underline;
    }

    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
  }

  .newTopicsList{
    @include props(mt, 90);
    
    @include mq(md) {
      @include props(mt, 90, 750);
    }

    .titleArea {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: baseline;
      @include props(pt, 90);
      border-top: solid 1px #251E1C;

      @include mq(md) {
        @include props(pt, 90, 750);
      }
    }

    .topicsTit {
      font-family: $Inconsolata;
      font-size: 2.8rem;
      font-weight: 600;
      letter-spacing: normal;
      line-height: 1.64;
      margin-right: 15px;
      @include props(mb, 30);

      @include mq(md) {
        @include fz_vw(54);
        @include props(mb, 30, 750);
      }
    }

    .topicsMore {
      a {
        font-size: 1.6rem;
        line-height: 1.64;
        letter-spacing: normal;

        @include mq(md) {
          @include fz_vw(30);
        }
      }
    }

    .flex {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      @include mq(md) {
        flex-wrap: wrap;
      }
    }

    .topicsCard {
      max-width: 30.61%;
      width: 100%;
      margin-right: 4.085%;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      .img{
        max-width: 100%;
        height: 173px;
        overflow: hidden;
        position: relative;
      }

      img {
        width: 100%;
        height: 173px;
        object-fit: cover;
        object-position: center;
      }

      .topicsCardTit {
        font-size: 1.6rem;
        line-height: 2;
        letter-spacing: normal;
        word-wrap : break-word;
        overflow-wrap : break-word;
        padding: 20px 7px 0;
      }

      @include mq(md) {
        max-width: 100%;
        margin-right: 0;
        @include props(mb, 50, 750);

        .img{
          max-width: 100%;
          height: auto;
          overflow: hidden;
          position: relative;
        }
  
        img {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
          @include props(he, 425, 750);
        }

        .topicsCardTit {
          @include fz_vw(30);
          line-height: 1.8;
          @include props(pt, 20, 750);
        }
      }
    }
  }
}
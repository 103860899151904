@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#de{
  #indexTop {
    .mainVisual {
      position: relative;
      @include props(mb, 130);
  
      @include mq(md) {
        @include props(mb, 130, 750);
      }
  
      .slider {
        width: 100%;
        position: relative;
        opacity: 0;
        transition: opacity .25s ease;
  
        img {
          width: 100%;
        }
  
        &.slick-initialized {
          opacity: 1;
        }
      }
  
      .slick-active{
        .img{
          animation: logo 3s 1 ease-in forwards;
        }
      }
  
      .logo{
        position: absolute;
        top: 50%;
        left: 50%;
        @include props(wd, 322);
        transform: translateX(-50%) translateY(-50%);
  
        @include mq(md) {
          @include props(wd, 422, 750);
        }
      }
  
      @keyframes logo {
        0% {
          opacity: 0;
        }
  
        40% {
          opacity: 0;
        }
      
        100% {
          opacity: 1;
        }
      }
  
      .scrollIcon{
        position: absolute;
        left: 50%;
        @include props(bottom, 35);
        @include props(wd, 65);
        @include props(he, 35);
        transform: translateX(-50%);
  
        @include mq(md) {
          @include props(wd, 65, 750);
          @include props(bottom, 70, 750);
        }
      }
    }
  
    .intr {
      @include props(mb, 200);
  
      @include mq(md) {
        @include props(mb, 200, 750);
      }
  
      .container {
        max-width: 1020px;
      }
  
      .intrTit {
        max-width: 848px;
        @include props(wd, 848);
        margin: 0 auto;
        @include props(mb, 110);
  
        @include mq(md) {
          max-width: 90%;
          width: 100%;
          @include props(mb, 110, 750);
        }
      }
  
      .flex {
        display: flex;
        justify-content: center;
  
        @include mq(md) {
          flex-wrap: wrap;
        }
  
        .imgBox {
          max-width: 326px;
          width: 100%;
          @include props(mt, 170);
  
          @include mq(md) {
            order: 2;
            max-width: 100%;
            width: 100%;
            @include props(mt, 0);
          }
        }
  
        .txtBox {
          max-width: 570px;
          @include props(wd, 570);
          @include props(ml, 84);
  
          @include mq(md) {
            order: 1;
            max-width: 100%;
            width: 100%;
            @include props(ml, 0);
            @include props(mb, 110, 750);
          }
  
          p {
            @include fz_vw(23);
            font-weight: normal;
            letter-spacing: normal;
            line-height: 1.9;
            color: #000;
  
            @include mq(tab) {
              @include fz_vw(26);
            }
  
            @include mq(md) {
              @include fz_vw(32);
            }
          }
        }
      }
    }
  
    .topics {
      @include props(mb, 255);
  
      @include mq(md) {
        @include props(mb, 255, 750);
      }
  
      .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: baseline;
      }
  
      .topicsTit {
        font-family: $Inconsolata;
        font-size: 3rem;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 1.64;
        margin-right: 15px;
        @include props(mb, 30);
  
        @include mq(md) {
          @include fz_vw(56);
          @include props(mb, 30, 750);
        }
      }
  
      .topicsMore {
        a {
          font-size: 1.8rem;
          line-height: 1.64;
          letter-spacing: normal;
  
          @include mq(md) {
            @include fz_vw(32);
          }
        }
      }
  
      .flex {
        width: 100%;
        display: flex;
        justify-content: flex-start;
  
        @include mq(md) {
          flex-wrap: wrap;
        }
      }
  
      .topicsCard {
        max-width: 30.61%;
        width: 100%;
        margin-right: 4.085%;
  
        &:nth-of-type(3n) {
          margin-right: 0;
        }
  
        .img{
          max-width: 100%;
          height: 173px;
          overflow: hidden;
          position: relative;
        }
  
        img {
          width: 100%;
          height: 173px;
          object-fit: cover;
          object-position: center;
        }
  
        .topicsCardTit {
          font-size: 1.8rem;
          line-height: 2;
          letter-spacing: normal;
          word-wrap : break-word;
          overflow-wrap : break-word;
          padding: 20px 7px 0;
        }
  
        @include mq(md) {
          max-width: 100%;
          margin-right: 0;
          @include props(mb, 50, 750);
  
          .img{
            max-width: 100%;
            height: auto;
            overflow: hidden;
            position: relative;
          }
    
          img {
            max-width: 100%;
            width: 100%;
            max-height: 100%;
            @include props(he, 425, 750);
          }
  
          .topicsCardTit {
            @include fz_vw(34);
            line-height: 1.8;
            @include props(pt, 20, 750);
          }
        }
      }
    }
  
    .tagiruContentArea {
      @include props(mb, 250);
  
      @include mq(md) {
        @include props(mb, 250, 750);
      }
  
      .tagiruContent {
        max-width: 1366px;
        width: 100%;
        margin: 0 auto;
        padding: 0 50px;
        @include props(mb, 70);
  
        @include mq(tab) {
          padding: 0;
        }
  
        @include mq(md) {
          padding: 0;
          @include props(mb, 190, 750);
        }
  
        a {
          display: flex;
          justify-content: flex-end;
  
          @include mq(md) {
            flex-wrap: wrap;
          }
        }
  
        .tagiruContentTit {
          @include fz_vw(22);
          font-weight: normal;
          line-height: 1.8;
          letter-spacing: normal;
          @include props(mt, 70);
          @include props(mr, 145);
  
          @include mq(tab) {
            width: 25vw;
            @include fz_vw(32);
          }
  
          @include mq(md) {
            max-width: 100%;
            width: 100%;
            @include fz_vw(42);
            padding: 0 20px;
            @include props(mt, 0);
            @include props(mb, 30, 750);
            @include props(mr, 0);
          }
        }
  
        .tagiruContentImg {
          @include props(wd, 820);
          overflow: hidden;
  
          @include mq(md) {
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  
    .founder {
      max-width: 1070px;
      padding: 0 20px;
      margin: 0 auto;
      @include props(mb, 130);
  
      @include mq(md) {
        @include props(mb, 130, 750);
      }
  
      .founderTop {
        display: flex;
        justify-content: space-between;
  
        @include mq(md) {
          flex-wrap: wrap;
        }
  
        .founderTopImg {
          max-width: 365px;
          width: 100%;
          @include props(mr, 50);
  
          @include mq(md) {
            width: 50%;
            margin: 0 auto;
            @include props(mb, 50, 750);
          }
        }
  
        .founderTopTxt {
          width: 100%;
  
          h3 {
            @include fz_vw(24);
            font-weight: 600;
            @include props(mb, 25);
  
            @include mq(tab) {
              @include fz_vw(34);
            }
  
            @include mq(md) {
              @include fz_vw(32);
              @include props(mb, 25, 750);
            }
          }
  
          p {
            @include fz_vw(21);
            line-height: 1.89;
  
            @include mq(tab) {
              @include fz_vw(26);
            }
  
            @include mq(md) {
              @include fz_vw(30);
            }
          }
        }
  
        .founderTopTit {
          font-family: Inconsolata;
          @include fz_vw(50);
          font-weight: 500;
          line-height: 1;
          @include props(mb, 50);
  
          @include mq(tab) {
            @include fz_vw(56);
          }
  
          @include mq(md) {
            @include fz_vw(56);
            @include props(mb, 50, 750);
          }
        }
      }
  
      .founderMessage {
        border-top: solid 1px #1A1311;
        padding: 6% 7.5% 0;
        @include props(mt, 118);
  
        @include mq(md) {
          padding: 45px 0 60px;
          @include props(mt, 115, 750);
        }
  
        .founderMessageLetter {
          max-width: 340px;
          @include props(wd, 340);
          @include props(mb, 55);
  
          @include mq(md) {
            max-width: 50%;
            width: 100%;
            @include props(mb, 55, 750);
          }
        }
  
        p {
          @include fz_vw(21);
          line-height: 2.1;
          letter-spacing: normal;
  
          &.rightTxt{
            text-align: right;
            @include props(pt, 40);
          }
  
          @include mq(tab) {
            @include fz_vw(26);
          }
  
          @include mq(md) {
            @include fz_vw(30);
  
            &.rightTxt{
              @include props(pt, 40, 750);
            }
          }
        }
      }
    }
  
  }
}
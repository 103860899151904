@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#ayurveda {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }
  
  .gridLayout{
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 265);

    &:last-of-type{
      margin-bottom: 0;
    }

    @include mq(md) {
      @include props(mb, 132, 750);
    }

    .container{
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;

      display: grid;
      grid-template-columns:  repeat(3,minmax(0, 1fr));
      grid-template-rows: auto 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0;

      @include mq(md) {
        max-width: 100%;
        padding: 0 20px;
				grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-template-rows: auto;
			}
    }
    .gridTit{
      width: 100%;
      font-family: $base-gothic-font;
      @include fz_vw(22);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      @include props(pr, 5);
      @include props(mb, 50);
      grid-area: 1 / 1 / 2 / 2;

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(34);
        @include props(pr, 0);
        @include props(mb, 40, 750);
				grid-area: 1 / 1 / 2 / 2;
			}
    }
    .gridTxt{
      width: 100%;
      @include fz_vw(16);
      line-height: 1.8;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pr, 30);
      grid-area: 2 / 1 / 3 / 2;

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(30);
        @include props(pr, 0);
				grid-area: 3 / 1 / 4 / 2;
			}
    }
    .img{
      @include props(wd, 757);
      grid-area: 1 / 2 / 3 / 4;
      max-width: 100%;
      margin: 0 0 0 auto;

      img{
        width: 100%;
        transition: none;
      }

      @include mq(md) {
        width: 100%;
        @include props(mb, 25, 750);
        @include props(ml, 0);
				grid-area: 2 / 1 / 3 / 2;
			}
    }

    .contentSlider {
      max-width: 100%;
      position: relative;
      opacity: 0;
      transition: opacity .25s ease;

      &.slick-initialized {
        opacity: 1;
      }
    }
  }
}
@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#facility {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }

  .facilityCon{
    max-width: 1366px;
    margin: 0 auto;
  }
  
  .container{
    max-width: 100%;
    width: 100%;
    @include props(pr, 50);
    @include props(pl, 155);
    margin: 0 auto;

    @include mq(md) {
      padding: 0 20px;
    }
  }

  .facilityConTxt{
    @include props(wd, 744);
    @include fz_vw(16);
    font-weight: normal;
    line-height: 1.8;
    letter-spacing: normal;
    @include props(p, 80);

    .facilityConTit{
      @include fz_vw(22);
      font-weight: 700;
      @include props(pb, 40);
    }

    @include mq(tab) {
      @include fz_vw(24);

      .facilityConTit{
        @include fz_vw(32);
      }
    }

    @include mq(md) {
      width: 100%;
      @include fz_vw(30);
      @include props(pt, 80, 750);
      @include props(pb, 80, 750);
      @include props(pl, 0, 750);
      @include props(pr, 0, 750);
      @include props(mb, 80, 750);

      .facilityConTit{
        @include fz_vw(34);
        @include props(pb, 40, 750);
      }
    }
  }

  .gridLayout{
    max-width: 1046px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: auto;
    @include props(mb, 200);

    @include mq(tab) {
      max-width: 85%;
    }

    @include mq(md) {
      max-width: 100%;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      @include props(mb, 200, 750);
    }

    .imgGrid01{
      @include props(wd, 605);
      grid-area: 1 / 3 / 3 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 450, 750);
      }
    }
    .imgGrid02{
      @include props(wd, 283);
      @include props(mr, 25);
      @include props(mb, 265);
      grid-area: 2 / 2 / 4 / 3;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 210, 750);
        margin-right: auto;
        margin-left: auto;
        @include props(mt, 50, 750);
        @include props(mb, 265, 750);
        grid-area: 2 / 1 / 5 / 3;
      }
    }
    .imgGrid03{
      @include props(wd, 743);
      @include props(mb, 330);
      grid-area: 5 / 1 / 6 / 4;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 550, 750);
        @include props(mb, 230, 750);
      }
    }
    .imgGrid04{
      @include props(wd, 481);
      margin: 0 0 0 auto;
      grid-area: 6 / 3 / 7 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 356, 750);
      }
    }
    .imgGrid05{
      @include props(wd, 690);
      @include props(mb, 145);
      grid-area: 6 / 1 / 7 / 4;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 440, 750);
        @include props(mb, 105, 750);
      }
    }
    .imgGrid06{
      @include props(wd, 375);
      @include props(pb, 70);
      margin: 0 0 0 auto;
      @include props(mr, 30);
      grid-area: 7 / 3 / 7 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 255, 750);
        @include props(pb, 70, 750);
        @include props(mr, 0);
      }
    }
    .imgGrid07{
      @include props(wd, 455);
      @include props(ml, 60);
      align-self: end;
      grid-area: 7 / 1 / 7 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 305, 750);
        @include props(ml, 60, 750);
      }
    }
    .imgGrid08{
      @include props(wd, 548);
      @include props(pb, 310);
      margin: 0 0 0 auto;
      @include props(mr, 30);
      @include props(mt, 145);
      grid-area: 8 / 3 / 8 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 548, 750);
        @include props(pb, 310, 750);
        @include props(mr, 30, 750);
        @include props(mt, 105, 750);
      }
    }
    .imgGrid09{
      @include props(wd, 370);
      @include props(ml, 20);
      align-self: end;
      grid-area: 8 / 1 / 8 / 4;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 270, 750);
        @include props(ml, 20, 750);
      }
    }
    .imgGrid10{
      @include props(wd, 610);
      margin: 0 0 0 auto;
      @include props(mt, 70);
      @include props(mb, 120);
      @include props(mr, 30);
      grid-area: 9 / 2 / 9 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 410, 750);
        @include props(mt, 70, 750);
        @include props(mb, 120, 750);
        @include props(mr, 0);
      }
    }
    .imgGrid24{
      @include props(wd, 740);
      margin: 0 auto;
      @include props(mb, 150);
      grid-area: 10 / 1 / 10 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 490, 750);
        @include props(mb, 150, 750);
      }
    }
    .imgGrid11{
      @include props(wd, 740);
      @include props(ml, 85);
      @include props(mb, 220);
      grid-area: 11 / 1 / 11 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 490, 750);
        @include props(ml, 85, 750);
        @include props(mb, 220, 750);
      }
    }
    .imgGrid12{
      @include props(wd, 415);
      @include props(pb, 235);
      margin: 0 0 0 auto;
      @include props(mr, 75);
      grid-area: 12 / 3 / 12 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 265, 750);
        @include props(pb, 235, 750);
        @include props(mr, 45, 750);
      }
    }
    .imgGrid13{
      @include props(wd, 400);
      @include props(ml, 60);
      align-self: end;
      grid-area: 12 / 1 / 12 / 4;
      @include mq(md) {
        max-width: 100%;
        @include props(wd, 250, 750);
        @include props(ml, 60, 750);
      }
    }
    .imgGrid25{
      @include props(wd, 740);
      margin: 0 auto;
      @include props(mt, 165);
      grid-area: 13 / 1 / 13 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 490, 750);
        @include props(mt, 165, 750);
      }
    }
    .imgGrid14{
      @include props(wd, 550);
      margin: 0 auto;
      @include props(mt, 165);
      grid-area: 14 / 1 / 14 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 300, 750);
        @include props(mt, 125, 750);
      }
    }
    .imgGrid15{
      @include props(wd, 560);
      @include props(pb, 900);
      @include props(mt, 210);
      grid-area: 15 / 1 / 15 / 4;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 360, 750);
        @include props(pb, 540, 750);
        @include props(mt, 170, 750);
      }
    }
    .imgGrid16{
      @include props(wd, 402);
      margin: 0 0 0 auto;
      align-self: center;
      grid-area: 15 / 3 / 15 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 252, 750);
      }
    }
    .imgGrid17{
      @include props(wd, 466);
      @include props(ml, 60);
      align-self: end;
      grid-area: 15 / 1 / 15 / 4;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 266, 750);
        @include props(ml, 60, 750);
      }
    }
    .imgGrid18{
      @include props(wd, 560);
      margin: 0 0 0 auto;
      @include props(mt, 130);
      @include props(mr, 75);
      grid-area: 16 / 2 / 16 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 360, 750);
        @include props(mt, 100, 750);
        @include props(mr, 45, 750);
      }
    }
    .imgGrid19{
      @include props(wd, 770);
      @include props(mt, 210);
      @include props(ml, 60);
      grid-area: 17 / 1 / 17 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 520, 750);
        @include props(mt, 160, 750);
        @include props(ml, 60, 750);
      }
    }
    .imgGrid20{
      @include props(wd, 370);
      @include props(pb, 400);
      margin: 0 0 0 auto;
      @include props(mt, 145);
      @include props(mr, 60);
      grid-area: 18 / 3 / 18 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 270, 750);
        @include props(pb, 200, 750);
        @include props(mt, 100, 750);
        @include props(mr, 30, 750);
      }
    }
    .imgGrid21{
      @include props(wd, 480);
      @include props(ml, 30);
      align-self: end;
      grid-area: 18 / 1 / 18 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 280, 750);
        @include props(ml, 30, 750);
      }
    }
    .imgGrid22{
      @include props(wd, 520);
      margin: 0 0 0 auto;
      @include props(mt, 90);
      @include props(mr, 120);
      grid-area: 19 / 2 / 19 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 320, 750);
        @include props(mt, 50, 750);
        @include props(mr, 90, 750);
      }
    }
    .imgGrid23{
      @include props(wd, 770);
      @include props(mt, 205);
      @include props(ml, 70);
      grid-area: 20 / 1 / 20 / 5;

      @include mq(md) {
        max-width: 100%;
        @include props(wd, 520, 750);
        @include props(mt, 155, 750);
        @include props(ml, 70, 750);
      }
    }
  }

  .slideLayoutTit{
    width: 100%;
    margin: 0 auto;
    @include fz_vw(24);
    line-height: 1.8;
    letter-spacing: normal;
    font-weight: 400;
    @include props(mb, 75);

    @include mq(tab) {
      @include fz_vw(34);
    }

    @include mq(md) {
      @include fz_vw(44);
      @include props(mb, 75, 750);
    }
  }
  .slideLayout{
    @include props(mb, 200);

    @include mq(md) {
      @include props(mb, 200, 750);
    }

    .contentArrowSlider{
      position: relative;
      @include props(wd, 820);
      margin: 0 auto;
      opacity: 0;
      transition: opacity .25s ease;

      &.slick-initialized {
        opacity: 1;
      }

      .slide{
        @include props(pr, 25);
        @include props(pl, 25);
      }

      .slide-arrow{
        position: absolute;
        top: 50%;
        @include props(wd, 35);
        @include props(he, 35);
        background-color: rgba($color: #fff, $alpha: 0);
        border-bottom: 1px solid #251E1C;
        border-right: 1px solid #251E1C;
        z-index: 1;
        cursor: pointer;
      }

      .prev-arrow{
        @include props(left, -35);
        transform:translateY(-50%) rotate(135deg);
      }
      .next-arrow{
        @include props(right, -35);
        transform:translateY(-50%) rotate(-45deg);
      }

      @include mq(md) {
        max-width: 100%;
        width: 100%;
        @include props(pr, 15, 750);
        @include props(pl, 15, 750);

        .slide{
          @include props(pr, 15, 750);
          @include props(pl, 15, 750);
        }
  
        .slide-arrow{
          @include props(wd, 30, 750);
          @include props(he, 30, 750);
        }
  
        .prev-arrow{
          @include props(left, -10, 750);
        }
        .next-arrow{
          @include props(right, - 10, 750);
        }
      }
    }
  }

  .locationLayoutTit{
    width: 100%;
    margin: 0 auto;
    @include fz_vw(24);
    line-height: 1.8;
    letter-spacing: normal;
    font-weight: 400;
    @include props(mb, 75);

    @include mq(tab) {
      @include fz_vw(34);
    }

    @include mq(md) {
      @include fz_vw(44);
      @include props(mb, 75, 750);
    }
  }
  .locationLayout{
    @include props(wd, 900);
    margin: 0 auto;

    @include mq(md) {
      max-width: 100%;
      width: 100%;
    }
  }
  .locationLayoutTxt {
    @include fz_vw(20);
    line-height: 1.8;
    font-weight: 400;
    letter-spacing: normal;

    dt{
      @include props(mb, 30);
    }

    @include mq(tab) {
      @include fz_vw(26);
      
      dt{
        @include props(mb, 40);
      }
    }

    @include mq(md) {
      @include fz_vw(32);

      dt{
        @include props(mb, 40, 750);
      }
    }
  }
}
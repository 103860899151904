@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#contact {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }

  .container{
    max-width: 940px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include mq(tab) {
      padding: 0 50px;
    }

    @include mq(md) {
      max-width: 100%;
    }
  }

  .contactForm{
    @include props(mb, 45);

    &.contact,
    &.error{
      .contactFormCon{
        dt{
          display: none;

          &.show{
            display: block;
            @include props(pl, 20);
          }
        }
      }
    }

    &.confirm{
      .contactTit{
        display: none;
      }
      .contactFormTxt{
        @include props(mb, 50);

        @include mq(md) {
          @include props(mb, 60, 750);
        }
      }
      .contactFormCon{
        @include props(mb, 80);

        @include mq(md) {
          @include props(mb, 80, 750);
        }

        dt{
          padding: 0 10px 0 0;
        }

        dd{
          &.select{
            &::after {
              display: none;
            }
          }

          .icon{
            display: none;
          }

          .period {
            display: block;
          }
        }
        &.mailCheck{
          display: none;
        }
      }   
      .annotationTxtList{
        .hidden{
          display: none;
        }
      }
    }

    &.complete{
      .contactTit{
        display: none;
      }
      .contactFormCon{
        dd{
          &.select{
            &::after {
              display: none;
            }
          }

          .icon{
            display: none;
          }

          .period {
            display: none;
          }
        }
      } 
      .annotationTxtList{
        .hidden{
          display: none;
        }
      }
    }

    @include mq(tab) {
      @include props(mb, 45, 750);
    }

    @include mq(md) {
      @include props(mb, 120, 750);
    }

    .contactTit{
      max-width: 1366px;
      width: 100%;
      margin: 0 auto;
      padding: 0 50px;
      @include fz_vw(22);
      line-height: 1.8;
      letter-spacing: normal;
      font-weight: 400;
      @include props(pr, 50);
      @include props(pl, 155);
      @include props(mb, 75);

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        @include fz_vw(42);
        padding: 0 20px;
        @include props(mb, 75, 750);
      }
    }

    .contactFormTxt{
      @include fz_vw(22);
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: normal;
      @include props(mb, 25);

      @include mq(tab) {
        @include fz_vw(32);
        @include props(mb, 25, 750);
      }

      @include mq(md) {
        @include fz_vw(28);
        @include props(mb, 40, 750);
      }
    }

    .contactFormAttentionTxt{
      @include fz_vw(16);
      font-weight: 400;
      line-height: 1;
      letter-spacing: normal;
      color: #BF1E2D;
      @include props(mb, 15);

      @include mq(tab) {
        @include fz_vw(24);
        @include props(mb, 15, 750);
      }
  
      @include mq(md) {
        @include fz_vw(30);
        @include props(mb, 30, 750);
      }
    }

    .contactSupplement{
      @include props(mb, 80);

      @include mq(md) {
        @include props(mb, 80, 750);
      }

      .contactSupplementTit{
        @include fz_vw(21);
        font-weight: 600;
        line-height: 1.8;
        @include props(mb, 10);

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(mb, 10, 750);
        }
      }
      .contactSupplementCon{
        @include fz_vw(18);
        font-weight: 400;
        line-height: 1.8;
        letter-spacing: normal;
        @include props(mb, 40);

        a{
          display: inline;
          text-decoration: underline;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(mb, 40, 750);
        }
      }
    }

    .topBackBtn{
      max-width: 100%;
      margin: 0 auto;
      @include props(wd, 210);
      @include props(mt, 200);

      @include mq(tab) {
        @include props(wd, 240);
      }

      @include mq(md) {
        @include props(wd, 320, 750);
        @include props(mt, 200, 750);
      }

      a{
        display: block;
        @include fz_vw(21);
        line-height: 1;
        letter-spacing: normal;
        color: #fff;
        text-align: center;
        padding: 20px;
        background-color: #251E1C;
        transition: opacity .3s;

        &:hover{
          cursor: pointer;
          opacity: 0.7;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(pt, 40, 750);
          @include props(pb, 40, 750);
        }
      }
    }
    form{
      .contactFormCon{
        display: flex;
        @include props(mb, 40);

        &::last-of-type{
          margin-bottom: 0;
        }

        dt{
          max-width: 100%;
          @include props(wd, 240);
          @include fz_vw(21);
          color: #251E1C;
        }

        dd{
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          max-width: 100%;
          width: 100%;
          font-family: $base-gothic-font;
          font-weight: 400;
          @include fz_vw(21);
          line-height: 1.8;
          color: #251E1C;

          &.select{
            position: relative;

            &::after {
              position: absolute;
              content: '';
              @include props(wd, 10);
              @include props(he, 10);
              @include props(right, 30);
              @include props(top, 30);
              transform:rotate(45deg);
              border-bottom: 2px solid #251E1C;
              border-right: 2px solid #251E1C;
              z-index: -1;

              @include mq(tab) {
                @include props(right, 40);
              }

              @include mq(md) {
                @include props(wd, 10, 750);
                @include props(he, 10, 750);
                @include props(right, 30, 750);
                @include props(top, 40, 750);
                border-bottom: 1px solid #251E1C;
                border-right: 1px solid #251E1C;
              }
            }
          }

          .icon{
            @include fz_vw(21);
            font-weight: 400;
            letter-spacing: normal;
            color: #BF1E2D;
            padding: 10px 10px 10px 0;

            &.hidden{
              opacity: 0;
            }

            @include mq(md) {
              @include fz_vw(30);
              @include props(pt, 10, 750);
              @include props(pb, 10, 750);
              @include props(pr, 10, 750);
            }
          }

          .period {
            display: none;
            width: 9%;
            text-align: right;
            padding: 0 2% 0 0;

            @include mq(md) {
              width: 14%;
            }
          }
        }

        &.mailCheck{
          display: block;
          @include props(mt, 50);
          @include props(mb, 50);

          dt{
            display: block;
            max-width: 100%;
            width: 100%;
          }
          dd{
            display: flex;
            align-items: flex-start;
            @include props(mt, 10);
            .icon{
              padding: 3px 10px 0 0;
            }
            .smallTxt{
              max-width: 97%;
              width: 100%;
              display: block;
              font-family: $base-font;
              @include fz_vw(18);
              @include props(pl, 46);
            }
            .mwform-checkbox-field{
              max-width: 97%;
              width: 100%;
            }
            .mwform-checkbox-field-text{
              display: block;
              position: relative;
              font-family: $base-font;
              @include fz_vw(21);
              line-height: 1.8;
              color: #251E1C;
              cursor: pointer;
              @include props(pl, 30);
              &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 18px;
                height: 18px;
                margin: auto;
                border: 1px solid #b7b7b7;
                background: #fff;
              }
              &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 3px;
                width: 14px;
                height: 14px;
                margin: auto;
                transform: scale(.5);
              }
            }
            input[type="checkbox"] {
              opacity: 0;
              position: absolute;
              width: 20px;
              padding: 9px;
              margin-top: 2px;
              -webkit-appearance: checkbox;
              &:checked {
                &+span {
                  &::after {
                    transition: .3s;
                    transform: scale(1);
                    background: #333;
                  }
                }
              }
            }
          }
        }

        @include mq(tab) {
          dt{
            @include props(wd, 260);
            @include fz_vw(24);
          }
          dd{
            @include fz_vw(24);
          }

          &.mailCheck{
            dd{
              .smallTxt{
                @include fz_vw(22);
                @include props(pl, 62);
              }
              .mwform-checkbox-field-text{
                @include fz_vw(24);
                @include props(pl, 40);
                  
                &::before {
                  left: 0;
                  width: 14px;
                  height: 14px;
                }
                &::after {
                  left: 3px;
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }

        @include mq(md) {
          flex-wrap: wrap;
          @include props(mb, 50, 750);

          &.mailCheck{
            @include props(mt, 60, 750);
            @include props(mb, 60, 750);

            dd{
              @include props(mt, 0);

              .smallTxt{
                @include fz_vw(28);
                letter-spacing: 1px;
                @include props(pl, 62, 750);
              }

              .mwform-checkbox-field {
                  max-width: 94%;
              }
              .icon{
                @include props(pt, 3, 750);
                @include props(pr, 10, 750);
              }
              .mwform-checkbox-field-text{
                @include fz_vw(30);
                @include props(pl, 44, 750);
                  
                &::before {
                  left: 0;
                  width: 13px;
                  height: 13px;
                }
                &::after {
                  left: 3px;
                  width: 9px;
                  height: 9px;
                }
              }
            }
          }
          
          dt{
            width: 100%;
            @include fz_vw(30);
            @include props(pb, 15, 750);
          }
          dd{
            @include fz_vw(30);
          }
        }
      }
      .eroorTxt{
        display: block;
        font-family: $base-font;
        @include fz_vw(18);
        line-height: 1;
        color: #BF1E2D;
        @include props(pt, 10);
        @include props(pl, 20);

        @include mq(tab) {
          @include fz_vw(22);
        }

        @include mq(md) {
          @include fz_vw(28);
          @include props(pt, 10, 750);
          @include props(pl, 20, 750);
        }
      }
      .annotationTxt{
        display: block;
        font-family: $base-font;
        @include fz_vw(18);
        line-height: 1.8;
        text-align: right;
        color: #251E1C;

        @include mq(tab) {
          @include fz_vw(22);
        }

        @include mq(md) {
          @include fz_vw(28);
          letter-spacing: 1px;
          text-align: left;
        }
      }
      .annotationTxtList{
        display: block;
        max-width: 100%;
        @include props(wd, 580);
        font-family: $base-font;
        @include fz_vw(18);
        line-height: 1.8;
        color: #251E1C;
        margin: 0 0 0 auto;

        @include mq(tab) {
          @include fz_vw(22);
          @include props(wd, 390, 750);
        }
    
        @include mq(md) {
          width: 100%;
          @include fz_vw(28);
          letter-spacing: 1px;
        }

        li{
          display: flex;
          align-items: baseline;
          &::before{
            content: "・";
          }
        }
      }
      input{
        max-width: 97%;
        width: 100%;
        font-family: $base-font;
        @include fz_vw(21);
        border: solid 1px #251E1C;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 5px 15px;
        @include props(he, 76);

        &::placeholder{
          color: #ccc;
        }
        
        &.hasDatepicker{
          max-width: 47%;

          &:first-of-type{
            margin-right: 2%;
          }
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          max-width: 96%;
          @include fz_vw(30);
          @include props(he, 90, 750);
          @include props(pt, 5, 750);
          @include props(pb, 5, 750);
          @include props(pr, 15, 750);
          @include props(pl, 15, 750);

          &.hasDatepicker{
            max-width: 45.5%;
  
            &:first-of-type{
              margin-right: 5%;
            }
          }
        }
      }
      select{
        max-width: 97%;
        width: 100%;
        font-family: $base-font;
        @include fz_vw(21);
        color: #251E1C;
        border: solid 1px #251E1C;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 5px 15px;
        @include props(he, 76);
        background-color: inherit;

        &.isEmpty{
          color: #ccc;
        }

        &::-ms-expand {
          display: none;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          max-width: 96%;
          @include fz_vw(30);
          @include props(he, 90, 750);
          @include props(pt, 5, 750);
          @include props(pb, 5, 750);
          @include props(pr, 15, 750);
          @include props(pl, 15, 750);
        }
      }
      textarea{
        max-width: 97%;
        width: 100%;
        font-family: $base-font;
        @include fz_vw(21);
        border: solid 1px #251E1C;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 15px;
        @include props(he, 242);
        resize: none;

        &::placeholder{
          color: #ccc;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          max-width: 96%;
          @include fz_vw(30);
          @include props(p, 15, 750);
          @include props(he, 260, 750);
        }
      }
      .btnArea{
        display: flex;
        justify-content: flex-end;
        @include props(mt, 20);

        @include mq(md) {
          @include props(mt, 20, 750);
        }
      }
      .contactFormBtn{
        display: block;
        @include fz_vw(21);
        line-height: 1;
        letter-spacing: normal;
        color: #fff;
        padding: 20px 60px;
        background-color: #251E1C;
        transition: opacity .3s;
        @include props(ml, 25);

        &:hover{
          cursor: pointer;
          opacity: 0.7;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(pt, 40, 750);
          @include props(pb, 40, 750);
          @include props(pr, 100, 750);
          @include props(pl, 100, 750);
          @include props(ml, 25, 750);
        }
      }
      .contactFormBackBtn{
        display: block;
        @include fz_vw(21);
        line-height: 1;
        letter-spacing: normal;
        color: #090000;
        padding: 20px 40px;
        background-color: #fff;
        border: solid 1px #251E1C;
        transition: all .3s;

        &:hover{
          cursor: pointer;
          color: #fff;
          background-color: #251E1C;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(pt, 40, 750);
          @include props(pb, 40, 750);
          @include props(pr, 65, 750);
          @include props(pl, 65, 750);
        }
      }
    }
  }

  .contactFaq{
    .contactFaqTit{
      max-width: 1366px;
      width: 100%;
      margin: 0 auto;
      padding: 0 50px;
      @include fz_vw(22);
      line-height: 1.8;
      letter-spacing: normal;
      font-weight: 400;
      @include props(pr, 50);
      @include props(pl, 155);
      @include props(mb, 75);

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        @include fz_vw(42);
        padding: 0 20px;
        @include props(mb, 75, 750);
      }
    }
    .faqCategoryList{
      display: flex;
      @include props(mb, 75);

      @include mq(md) {
        flex-wrap: wrap;
        @include props(mb, 75, 750);
      }

      .faqCategory{
        max-width: 23%;
        width: 100%;
        @include fz_vw(18);
        font-weight: 400;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        padding: 20px 25px;
        margin-right: 2.5%;
        border: solid 1px #251E1C;
        transition: all .4s;
        cursor: pointer;

        &:last-of-type{
          margin-right: 0;
        }

        &:hover{
          color: #fff;
          background-color: #251E1C;
        }
        &.active{
          color: #fff;
          background-color: #251E1C;
        }

        @include mq(tab) {
          @include fz_vw(24);
          padding: 20px;
        }
  
        @include mq(md) {
          max-width: 48.5%;
          margin-right: 1.5%;
          margin-bottom: 1.5%;
          @include props(pt, 40, 750);
          @include props(pb, 40, 750);
          @include props(pr, 20, 750);
          @include props(pl, 20, 750);
          @include fz_vw(30);
        }
      }
    }
    .faqList{
      display: none;

      &.active{
        display: block;
        animation: tabAnim ease 1s forwards;
      }

      @keyframes tabAnim {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      a{
        display: inline;
        text-decoration: underline;
      }

      .question{
        @include fz_vw(21);
        font-weight: 600;
        line-height: 1.9;
        letter-spacing: normal;
        @include props(mb, 15);

        @include mq(tab) {
          @include fz_vw(24);
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(mb, 15, 750);
        }
      }
      .answer{
        @include fz_vw(16);
        font-weight: 400;
        line-height: 1.8;
        letter-spacing: normal;
        @include props(mb, 60);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(tab) {
          @include fz_vw(24);
        }
  
        @include mq(md) {
          @include fz_vw(30);
          @include props(mb, 100, 750);
        }

        span{
          @include fz_vw(21);
          font-weight: 400;
          line-height: 1.8;

          @include mq(tab) {
            @include fz_vw(24);
          }

          @include mq(md) {
            @include fz_vw(30);
          }
        }
      }
    }
  }

  .stayFee{
    @include props(mb, 100);

    @include mq(tab) {
      @include props(mb, 100, 750);
    }

    @include mq(md) {
      @include props(mb, 120, 750);
    }

    .stayFeeTit{
      max-width: 1366px;
      width: 100%;
      margin: 0 auto;
      padding: 0 50px;
      @include fz_vw(22);
      line-height: 1.8;
      letter-spacing: normal;
      font-weight: 400;
      @include props(pr, 50);
      @include props(pl, 155);
      @include props(mb, 75);

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        @include fz_vw(42);
        padding: 0 20px;
        @include props(mb, 75, 750);
      }
    }

    .stayFeeTxt{
      @include fz_vw(16);
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: normal;
      @include props(mb, 20);

      strong{
        display: inline-block;
        @include fz_vw(18);
        font-weight: 400;
        border-bottom: solid 1px #000;
        margin: 0 0 10px;
      }

      @include mq(tab) {
        @include fz_vw(24);

        strong{
          @include fz_vw(26);
        }
      }
  
      @include mq(md) {
        @include fz_vw(30);
        @include props(mb, 55, 750);

        strong{
          @include fz_vw(32);
          margin: 0 0 8px;
        }
      }
    }

    .stayFeeTable{
      @include fz_vw(18);
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: normal;
      text-align: center;
      border: solid 1px #251e1c;
      @include props(mb, 20);

      thead{
        th{
          font-weight: 700;
        }
      }

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        display: block;
        @include fz_vw(30);
        text-align: left;
        border: none;
        @include props(mb, 30, 750);

        tbody{
          display: block;
        }

        tr{
          display: flex;
          flex-wrap: wrap;

          &:first-of-type{
            border: none;

            .day{
              @include props(mt, 0);
            }
          }
        }
      }

      th{
        font-weight: 400;
        text-align: center;
        border: solid 1px #251e1c;
        padding: 25px;

        @include mq(md) {
          display: block;
          max-width: 25%;
          width: 100%;
          text-align: left;
          border: none;
          padding: 0;
          @include props(pb, 25, 750);

          &.day{
            max-width: 100%;
            width: 100%;
            font-weight: 700;
            border-bottom: solid 1px #251e1c;
            @include props(pb, 15, 750);
            @include props(mt, 45, 750);
            @include props(mb, 20, 750);
          }
        }
      }
      td{
        border: solid 1px #251e1c;
        padding: 25px;

        @include mq(md) {
          display: block;
          max-width: 75%;
          width: 100%;
          border: none;
          padding: 0;
          @include props(pb, 25, 750);
        }  
      }
    }

    .stayFeeSupplement{
      max-width: 100%;
      @include props(wd, 530);
      @include fz_vw(16);
      font-weight: 400;
      line-height: 1.8;
      letter-spacing: normal;
      margin: 0 0 0 auto;

      @include mq(tab) {
        @include fz_vw(24);
        @include props(wd, 450, 750);
      }
  
      @include mq(md) {
        width: 100%;
        @include fz_vw(30);
      }

      li{
        display: flex;
        align-items: baseline;
        &::before{
          content: "・";
        }
      }
    }
  }
}
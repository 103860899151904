@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#schedule {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }

  .scheduleFlow {
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 305);

    @include mq(md) {
      @include props(mb, 180, 750);
    }

    .container {
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;

      @include mq(md) {
        max-width: 100%;
        padding: 0 20px;
      }
    }

    .scheduleTxt{
      @include fz_vw(16);
      font-weight: normal;
      line-height: 1.8;
      letter-spacing: normal;
      @include props(pb, 100);
      @include props(pr, 80);
      @include props(pl, 80);
  
      @include mq(tab) {
        @include fz_vw(24);
      }
  
      @include mq(md) {
        width: 100%;
        @include fz_vw(30);
        @include props(pb, 80, 750);
        @include props(pl, 0, 750);
        @include props(pr, 0, 750);
        @include props(mb, 80, 750);
      }
    }

    .scheduleFlowTit {
      font-family: $base-gothic-font;
      @include fz_vw(22);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      @include props(mb, 55);

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        @include fz_vw(34);
        @include props(mb, 55, 750);
      }
    }

    .flex {
      display: flex;
      align-items: flex-start;
      @include props(pl, 75);

      @include mq(tab) {
        @include props(pl, 0);
      }

      @include mq(md) {
        display: block;
        @include props(pl, 35, 750);
      }
    }

    .flowBox {
      max-width: 50%;
      width: 100%;
      font-family: $base-gothic-font;
      @include fz_vw(22);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      position: relative;
      @include props(pt, 45);
      @include props(pb, 90);
      @include props(pr, 40);

      @include mq(tab) {
        @include fz_vw(30);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(34);
        @include props(pt, 45, 750);
        @include props(pb, 90, 750);
        @include props(pr, 0);
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        margin: auto;
        width: 3px;
        height: 100%;
        background-color: #000;
        top: 0;
        left: 29.8%;

        @include mq(tab) {
          left: 29.9%;
        }

        @include mq(md) {
          left: 21.8%;
        }
      }

      &.clearFix{
        &::after{
          content: "";
          display: block;
          clear: both;
        }
      }

      .balloon {
        position: absolute;
        @include props(wd, 140);
        @include props(top, -45);
        left: 65%;
        @include fz_vw(16);
        line-height: 1;
        font-weight: 500;
        text-align: center;
        border: 1px solid #000000;
        border-radius: 10px;
        

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 90%;
          @include props(left, 12);
          border: 8px solid transparent;
          border-right: 18px solid #fff;
          transform: rotate(-45deg);
          z-index: 1;

          @include mq(tab) {
            @include props(bottom, -13);
            @include props(left, 15);
          }

          @include mq(md) {
            @include props(bottom, -9);
            @include props(left, 11);
          }
        }

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 95%;
          @include props(left, 10);
          border: 8px solid transparent;
          border-right: 18px solid #000;
          transform: rotate(-45deg);
          z-index: -1;

          @include mq(tab) {
            @include props(bottom, -15);
            @include props(left, 13);
          }
        }

        a {
          position: relative;
          @include props(pt, 8);
          @include props(pb, 8);
          @include props(pl, 15);
          @include props(pr, 15);
          display: block;
          background-color: #fff;
          border-radius: 10px;
          z-index: 2;
        }

        @include mq(md) {
          @include fz_vw(30);
          @include props(wd, 140, 750);
          @include props(top, -45, 750);
          left: 65%;
          @include fz_vw(16);
          border-radius: 5px;

          &::before {
            @include props(left, 12, 750);
            border: 4px solid transparent;
            border-right: 9px solid #fff;
          }

          &::after {
            @include props(left, 10, 750);
            border: 4px solid transparent;
            border-right: 9px solid #000;
          }

          a {
            @include props(pt, 8, 750);
            @include props(pb, 8, 750);
            @include props(pl, 15, 750);
            @include props(pr, 15, 750);
            border-radius: 5px;
          }
        }
      }

      .left {
        width: 32.5%;
        float: left;
        text-align: right;
        @include props(pr, 50);

        &.margin {
          margin-right: 50%;
        }

        @include mq(md) {
          width: 22%;
          @include props(pr, 50, 750);
        }
      }

      .right {
        width: 67.5%;
        float: right;
        @include props(pl, 45);
        position: relative;

        &::before {
          content: "";
          display: block;
          position: absolute;
          @include props(top, 12);
          @include props(left, -7);
          @include props(wd, 14);
          @include props(he, 14);
          border-radius: 100%;
          background-color: #000;

          @include mq(tab) {
            @include props(top, 18);
          }

          @include mq(md) {
            @include props(top, 16, 750);
            @include props(left, -12, 750);
            @include props(wd, 28, 750);
            @include props(he, 28, 750);
          }
        }

        &.margin {
          margin-left: 50%;
        }

        &.roundNon{
          &::before{
            display: none;
          }
        }

        @include mq(md) {
          width: 78%;
          @include props(pl, 45, 750);
        }
      }

      .flow {
        @include props(mb, 20);

        @include mq(md) {
          @include props(mb, 40, 750);
        }

        &:nth-of-type(1) {
          @include props(mb, 45);

          @include mq(md) {
            @include props(mb, 106, 750);
          }
        }

        &:nth-of-type(3) {
          @include props(mb, 40);

          @include mq(md) {
            @include props(mb, 80, 750);
          }
        }

        &:nth-of-type(4) {
          @include props(mb, 80);

          @include mq(md) {
            @include props(mb, 410, 750);
          }
        }

        &:nth-of-type(5) {
          @include props(mb, 55);

          @include mq(tab) {
            @include props(mb, 75);
          }

          @include mq(md) {
            @include props(mb, 95, 750);
          }
        }

        &:nth-of-type(7) {
          @include props(mb, 55);

          @include mq(tab) {
            @include props(mb, 75);
          }

          @include mq(md) {
            @include props(mb, 95, 750);
          }
        }

        &:nth-of-type(8) {
          @include props(mb, 90);

          @include mq(tab) {
            @include props(mb, 100);
          }

          @include mq(md) {
            @include props(mb, 130, 750);
          }
        }

        &:nth-of-type(9) {
          @include props(mt, -25);

          @include mq(tab) {
            @include props(mt, -30);
          }

          @include mq(md) {
            @include props(mt, -50, 750);
          }
        }

        &:nth-of-type(10) {
          @include props(mb, 65);

          @include mq(md) {
            @include props(mb, 105, 750);
          }
        }

        &:nth-of-type(11) {
          @include props(mb, 110);

          @include mq(md) {
            @include props(mb, 730, 750);
          }
        }

        &:nth-of-type(12) {
          @include props(mb, 75);

          @include mq(md) {
            @include props(mb, 125, 750);
          }
        }

        &:nth-of-type(13) {
          @include props(mb, 75);

          @include mq(md) {
            @include props(mb, 135, 750);
          }
        }

        &:nth-of-type(14) {
          @include props(mb, 60);

          @include mq(md) {
            @include props(mb, 110, 750);
          }
        }

        &:nth-of-type(15) {
          @include props(mb, 20);

          @include mq(md) {
            @include props(mb, 40, 750);
          }
        }

        &:nth-of-type(16) {
          @include props(mb, 45);

          @include mq(md) {
            @include props(mb, 85, 750);
          }
        }

        &:nth-of-type(17) {
          @include props(mt, -25);

          @include mq(tab) {
            @include props(mt, -30);
          }

          @include mq(md) {
            @include props(mt, -50, 750);
          }
        }

        &:nth-of-type(18) {
          @include props(mb, 45);

          @include mq(md) {
            @include props(mb, 135, 750);
          }
        }

        &:nth-of-type(19) {
          @include mq(tab) {
            @include props(mb, 30);
          }

          @include mq(md) {
            @include props(mb, 0);
          }
        }

        &:nth-of-type(21) {
          @include props(mt, -55);
          @include props(mb, 90);

          @include mq(tab) {
            @include props(mt, -70);
          }

          @include mq(md) {
            @include props(mt, -95, 750);
            @include props(mb, 0);
          }
        }

        &:nth-of-type(22) {
          @include props(mt, 70);

          @include mq(md) {
            @include props(mt, 0);
          }
        }
      }
    }

    .imgBox {
      max-width: 50%;
      width: 100%;
      display: grid;

      @include mq(md) {
        display: none;
      }
    }

    .img01 {
      @include props(wd, 266);
      @include props(mb, 20);
      grid-area: 1 / 1 / 3 / 4;

      @include mq(md) {
        @include props(wd, 266, 750);
        @include props(mt, 20);
      }
    }

    .img02 {
      @include props(wd, 266);
      grid-area: 3 / 3 / 5 / 6;

      @include mq(md) {
        @include props(wd, 266, 750);
        @include props(mt, 20);
      }
    }

    .img03 {
      @include props(wd, 283);
      @include props(mt, 85);
      @include props(mb, 90);
      grid-area: 5 / 1 / 8 / 4;

      @include mq(md) {
        @include props(wd, 283, 750);
        @include props(mt, 20);
        @include props(mb, 0);
      }
    }

    .img04 {
      @include props(wd, 272);
      grid-area: 8 / 3 / 10 / 6;

      @include mq(md) {
        @include props(wd, 272, 750);
        @include props(mt, 20);
      }
    }
  }

  .gridLayout {
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 265);

    &:last-of-type {
      margin-bottom: 0;
    }

    @include mq(md) {
      @include props(mb, 132, 750);
    }

    .container {
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;

      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: auto 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0;

      @include mq(md) {
        max-width: 100%;
        padding: 0 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-template-rows: auto;
      }
    }

    .gridTit {
      width: 100%;
      font-family: $base-gothic-font;
      @include fz_vw(22);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      @include props(pr, 5);
      @include props(mb, 50);
      grid-area: 1 / 1 / 2 / 2;

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(34);
        @include props(pr, 0);
        @include props(mb, 40, 750);
        grid-area: 1 / 1 / 2 / 2;
      }
    }

    .gridTxt {
      width: 100%;
      @include fz_vw(16);
      line-height: 1.8;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pr, 30);
      grid-area: 2 / 1 / 3 / 2;

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(30);
        @include props(pr, 0);
        grid-area: 3 / 1 / 4 / 2;
      }
    }

    .img {
      @include props(wd, 757);
      grid-area: 1 / 2 / 3 / 4;
      max-width: 100%;
      margin: 0 0 0 auto;

      img {
        width: 100%;
        transition: none;
      }

      @include mq(md) {
        width: 100%;
        @include props(mb, 25, 750);
        @include props(ml, 0);
        grid-area: 2 / 1 / 3 / 2;
      }
    }

    .contentSlider {
      max-width: 100%;
      position: relative;
      opacity: 0;
      transition: opacity .25s ease;

      &.slick-initialized {
        opacity: 1;
      }
    }
  }

  .gridLayout02 {
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 240);

    @include mq(md) {
      @include props(pt, 60, 750);
      @include props(mb, 240, 750);
    }

    .container {
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;

      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 0;
      justify-items: center;

      @include mq(md) {
        display: block;
        max-width: 100%;
        padding: 0 20px;
      }
    }

    .gridList {
      @include props(wd, 645);
      display: flex;
      grid-area: 1 / 1 / 2 / 5;
      @include props(pr, 30);
      @include props(mb, 40);

      @include mq(tab) {
        @include props(pr, 0);
      }

      @include mq(md) {
        display: block;
        max-width: 100%;
        width: 100%;
        @include props(pr, 0);
        @include props(mb, 40, 750);
      }

      dt {
        width: 27%;
        @include fz_vw(22);
        font-family: $base-gothic-font;
        font-weight: 500;
        line-height: 1.8;
        @include props(pr, 50);

        @include mq(tab) {
          width: 30%;
          @include fz_vw(30);
          @include props(pr, 30);
        }

        @include mq(md) {
          width: 100%;
          @include fz_vw(32);
          @include props(pr, 0);
          @include props(mb, 10, 750);
        }
      }

      dd {
        width: 73%;
        @include fz_vw(24);
        font-family: $base-gothic-font;
        font-weight: 500;
        line-height: 1.8;

        .list {
          display: flex;

          &::before {
            content: "・";
            display: block;
          }
        }

        @include mq(tab) {
          width: 70%;
          @include fz_vw(30);
        }

        @include mq(md) {
          width: 100%;
          @include fz_vw(30);
        }
      }
    }

    .gridTxt {
      @include props(wd, 352);
      @include fz_vw(16);
      line-height: 1.8;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pb, 115);
      @include props(mr, 120);
      grid-area: 2 / 1 / 4 / 5;
      justify-self: end;

      @include mq(tab) {
        @include props(wd, 402);
        @include fz_vw(24);
        @include props(mr, 45);
      }

      @include mq(md) {
        max-width: 100%;
        width: 100%;
        @include fz_vw(30);
        @include props(pb, 0);
        @include props(mr, 0);
        @include props(mb, 80, 750);
      }
    }

    .img {
      @include props(wd, 522);
      max-width: 100%;
      grid-area: 1 / 5 / 4 / 8;
      align-self: end;
      justify-self: end;
      @include props(pl, 10);

      img {
        transition: none;
        @include props(wd, 374);
        margin: 0 auto 0 0;
      }

      @include mq(md) {
        @include props(wd, 374, 750);
        margin: 0 0 0 auto;
        @include props(pl, 0);

        img {
          width: 100%;
        }
      }
    }
  }
}
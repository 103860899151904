@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
  min-height:0;
  min-width : 0;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #231815;
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.container {
  max-width: 955px;
  padding: 0 20px;
  margin: auto;
}

a {
  color: #555;
  display: block;
  cursor: pointer; 
}

img {
  margin: auto;
}

/* 共通デザイン
------------------------------------------------------------- */
.ofi {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'
}

.parallax {
  position: relative;
  overflow: hidden;
  width: 100vw;
  margin: 0 calc(50% - 50vw) 40px;
  height: 65vw;

  @include mq(pc) {
    width: 100vw;
    height: 51.2vw;
    max-height: 700px;
    margin-bottom: 15%;
  }

  &Img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 200%;
    max-height: 952px;
    object-fit: cover;

    @include mq(md) {
      height: 130%;
    }
  }
}

.fadeIn {
  opacity: 0;
  transform: translateY(80px);
  transition: 1.5s;

  @include mq(md) {
    transform: translateY(30px);
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade {
  opacity: 0;
  transition: 1.5s .5s;

  &.active {
    opacity: 1;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  transition: opacity 1s 1s, visibility 1s 2s;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: auto;
    font-size: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-indent: -9999em;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    border-right: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    border-left: 2px solid #000;
    transform: translateZ(0);
    animation: load 1.1s infinite linear;
    transition: opacity 1s;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;

    &::after {
      opacity: 0;
    }
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.zoomIn {
	overflow: hidden;
	position: relative;
	img {
		transition: .6s ease-in-out;
		transform: scale(1);
		backface-visibility: hidden;
	}
	&:hover {
		img {
			transform: scale(1.03);
			backface-visibility: hidden;
			@include mq(md) {
				transform: scale(1);
			}
		}
		img.notZoom {
			transform: scale(1);
		}
	}
}

.mainTitleArea{
  max-width: 1366px;
  margin: 0 auto;
  @include props(pt, 235);
  @include props(pb, 135);

  .mainTit{
    width: 100%;
    margin: 0 auto;
    @include props(pr, 50);
    @include props(pl, 155);
    @include fz_vw(22);
    line-height: 1.8;
    letter-spacing: normal;
    font-weight: 400;
  }

  @include mq(tab) {
    .mainTit{
      @include fz_vw(32);
    }
  }

  @include mq(md) {
    @include props(pt, 235, 750);
    @include props(pb, 0, 750);
    @include props(mb, 135, 750);

    .mainTit{
      padding: 0 20px;
      @include fz_vw(42);
    }
  }
}


/* ドイツ語
------------------------------------------------------------- */
#de{
  .mainTitleArea{
    .mainTit{
      @include fz_vw(24);
    }
  
    @include mq(tab) {
      .mainTit{
        @include fz_vw(30);
      }
    }
  
    @include mq(md) {
      .mainTit{
        @include fz_vw(44);
      }
    }
  }
}

/* 英語
------------------------------------------------------------- */
#en{
  .mainTitleArea{
    .mainTit{
      @include fz_vw(24);
    }
  
    @include mq(tab) {
      .mainTit{
        @include fz_vw(30);
      }
    }
  
    @include mq(md) {
      .mainTit{
        @include fz_vw(44);
      }
    }
  }
}


/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
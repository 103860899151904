@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: background-color 1s, opacity 1s;
  pointer-events: none;
  @include props(pt, 58);
  @include props(pb, 25);
  @include props(pl, 62);
  @include props(pr, 25);
  opacity: 0;
  visibility: hidden;

  @include mq(md) {
    @include props(pt, 25, 750);
    @include props(pb, 25, 750);
    @include props(pl, 30, 750);
    @include props(pr, 30, 750);
  }

  * {
    pointer-events: auto;
  }
  &.show{
    opacity: 1;
    visibility: visible;
  }
  &.scrollDown {
    @include mq(md) {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .headerLogo{
    max-width: 180px;
    @include props(wd, 180);

    @include mq(md) {
      @include props(wd, 135, 750);
    }
  }

  .headerNav {
    transition: 1s;

    ul {
      position: fixed;
      top: 0;
      right: 0;
      background-color: #F7F7F7;
      max-height: 100vh;
      transform: translateY(-100%);
      visibility: hidden;
      transition: transform 1s, visibility 2s;
      flex-direction: column;
      @include props(pt, 140);
      @include props(pb, 60);
      @include props(pl, 55);
      @include props(pr, 55);
      overflow-y: scroll;
      font-family: $base-gothic-font;
      z-index: 2;

      @include mq(md) {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        @include props(pt, 145, 750);
        @include props(pb, 60, 750);
        @include props(pl, 100, 750);
        @include props(pr, 100, 750);
      }
    }
    li {
      opacity: 0;
      transform: translateY(20px);
      @include props(mb, 45);

      @include mq(md) {
        @include props(mb, 85, 750);
      }

      &.reserve{
        border-top: solid 1px #231815;
        @include props(pt, 30);

        @include mq(md) {
          @include props(pt, 45, 750);
          @include props(mb, 45, 750);
        }
      }

      &.lang{
        @include mq(md) {
          @include props(mb, 35, 750);

          .headerNavSubmenuLang {
            position: static;
            height: auto;
            font-family: $base-gothic-font;
            font-size: inherit;
            border-top: solid 1px #251E1C;
            border-bottom: solid 1px #251E1C;
            @include props(pt, 45, 750);
            @include props(pb, 45, 750);
            
            a{
              @include props(pr, 0);
              @include props(pl, 15);
            }
          }
        }
      }

      a {
        @include fz_vw(22);
        display: block;
        white-space: nowrap;
        @include props(pl, 15);

        @include mq(tab) {
          @include fz_vw(32);
        }

        @include mq(md) {
          @include fz_vw(28);
          text-align: left;
        }
      }
    }
    &.show {
      ul {
        transform: translateY(0);
        visibility: visible;
      }
      li {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 2s 1s, transform 1s .8s;
      }
    }
  }

  .instagramBtn{
    @include props(wd, 30);
    @include props(mr, 20);

    @include mq(md) {
      @include props(wd, 50, 750);
      @include props(mr, 15, 750);
    }
  }

  .facebookBtn{
    @include props(wd, 30);
    @include props(mr, 35);

    @include mq(md) {
      @include props(wd, 50, 750);
      @include props(mr, 30, 750);
    }
  }

  .headerNavSubmenuLang {
    display: flex;
    align-items: center;
    font-family: $base-font;
    position: absolute;
    @include props(top, 57);
    @include props(right, 130);
    @include props(he, 25);
    z-index: 1;
    
    a {
      font-size: 1.6rem;

      &:hover {
        opacity: .8;
      }
    }

    @include mq(tab) {
      @include props(top, 60);
      @include props(he, 30);
    }

    @include mq(md) {
      @include fz_vw(26);
      @include props(top, 55, 750);
      @include props(right, 140, 750);

      a {
        @include mq(md) {
          @include fz_vw(32);
          @include props(pr, 5, 750);
          @include props(pl, 5, 750);
        }
      }
    }
  }

  .headerNavBtn {
    position: absolute;
    @include props(top, 57);
    @include props(right, 48);
    @include props(wd, 41);
    @include props(he, 25);
    cursor: pointer;
    outline: none;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0);

    @include mq(tab) {
      @include props(top, 60);
      @include props(he, 30);
    }

    @include mq(md) {
      @include props(top, 42, 750);
      @include props(right, 35, 750);
      @include props(wd, 68, 750);
      @include props(he, 42, 750);
    }

    span {
      display: inline-block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #333;
      transition: .5s ease-in-out;
      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 49%;
      }
      &:nth-of-type(3) {
        bottom: 0;
      }
    }

    &.open {
      span {
        &:nth-of-type(1) {
          transform: translateY(50%) rotate(-135deg);
          top: auto;
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-50%) rotate(135deg);
          bottom: auto;
        }
      }
    }
  }

  .reserveBtn{
    position: absolute;
    z-index: 1;
    @include props(top, 26, 750);
    @include props(right, 145, 750);

    a{
      @include fz_vw(28);
      line-height: 1;
      border: solid 1px #251E1C;
      @include props(pt, 20, 750);
      @include props(pb, 20, 750);
      @include props(pl, 25, 750);
      @include props(pr, 25, 750);
    }
  }
}

#de{
  #header {
    .headerNav {
      li {
        a {
          @include fz_vw(24);
  
          @include mq(tab) {
            @include fz_vw(34);
          }
  
          @include mq(md) {
            @include fz_vw(30);
          }
        }

        &.lang{
          @include mq(md) {
            .headerNavSubmenuLang {
              font-size: inherit;
            }
          }
        }
      }
    }
  
    .headerNavSubmenuLang {
      a {
        font-size: 1.8rem;
      }
  
      @include mq(md) {
        @include fz_vw(26);
        a {
          @include mq(md) {
            @include fz_vw(32);
          }
        }
      }
    }
    .reserveBtn{
      a{
        @include fz_vw(30);
      }
    }
  }
}

#en{
  #header {
    .headerNav {
      li {
        a {
          @include fz_vw(24);
  
          @include mq(tab) {
            @include fz_vw(34);
          }
  
          @include mq(md) {
            @include fz_vw(30);
          }
        }

        &.lang{
          @include mq(md) {
            .headerNavSubmenuLang {
              font-size: inherit;
            }
          }
        }
      }
    }
  
    .headerNavSubmenuLang {
      a {
        font-size: 1.8rem;
      }
  
      @include mq(md) {
        @include fz_vw(26);
        a {
          @include mq(md) {
            @include fz_vw(32);
          }
        }
      }
    }
    .reserveBtn{
      a{
        @include fz_vw(30);
      }
    }
  }
}
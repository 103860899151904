@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#concept {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }

  .conseptArea{
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 220);

    @include mq(md) {
      @include props(mb, 220, 750);
    }

    .container{
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;

      @include mq(md) {
        padding: 0 20px;
			}
    }

    .img{
      @include props(mb, 120);

      @include mq(md) {
        @include props(mb, 120, 750);
      }
    }

    .conseptAreaTit{
      width: 100%;
      @include fz_vw(27);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      @include props(mb, 60);
      @include props(pl , 200);

      @include mq(tab) {
        @include fz_vw(37);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(39);
        @include props(mb, 70, 750);
        @include props(pl , 0);
			}
    }

    .conseptAreaTxt{
      width: 100%;
      @include fz_vw(20);
      line-height: 2;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pl , 200);

      strong{
        font-weight: bold;
      }

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(30);
        @include props(pl , 0);
			}
    }
  }
  
  .gridLayout{
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 265);

    &:last-of-type{
      margin-bottom: 0;
    }

    @include mq(md) {
      @include props(mb, 265, 750);
    }

    &.grid01{
      .img02{
        @include props(wd, 360);
        margin-left: auto;
        margin-right: auto;
        grid-area: 3 / 1 / 4 / 3;

        @include mq(md) {
          @include props(wd, 360, 750);
          @include props(ml, 50, 750);
          grid-area: 4 / 1 / 4 / 2;
        }
      }
    }
    &.grid02{
      .img02{
        @include props(wd, 394);
        grid-area: 3 / 2 / 3 / 4;
        margin-left: auto;
        @include props(mr, 60);

        @include mq(md) {
          @include props(wd, 394, 750);
          @include props(mr, 60, 750);
          grid-area: 4 / 1 / 4 / 2;
        }
      }
    }
    &.grid03{
      .img02{
        @include props(wd, 509);
        margin-left: auto;
        margin-right: auto;
        grid-area: 3 / 1 / 4 / 3;

        @include mq(md) {
          @include props(wd, 509, 750);
          grid-area: 4 / 1 / 4 / 2;
        }
      }
    }
    &.grid04{
      .img02{
        @include props(wd, 374);
        margin-left: -110px;
        grid-area: 3 / 2 / 3 / 3;

        @include mq(tab) {
          margin-left: -55px;
        }

        @include mq(md) {
          @include props(wd, 374, 750);
          @include props(ml, 60, 750);
          grid-area: 4 / 1 / 4 / 2;
        }
      }
    }

    .container{
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;
      display: grid;
      grid-template-columns:  repeat(3,minmax(0, 1fr));
      grid-template-rows: auto 1fr auto;
      grid-column-gap: 0;
      grid-row-gap: 0;

      @include mq(md) {
        padding: 0 20px;
        grid-template-columns: repeat(1, minmax(100px, 1fr));
        grid-template-rows: auto;
			}
    }
    .gridTit{
      width: 100%;
      font-family: $base-gothic-font;
      @include fz_vw(22);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      @include props(mb, 50);
      @include props(pr, 5);
      grid-area: 1 / 1 / 2 / 2;

      @include mq(tab) {
        @include fz_vw(32);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(34);
        @include props(pr, 0);
        @include props(mb, 40, 750);
				grid-area: 1 / 1 / 2 / 2;
			}
    }
    .gridTxt{
      width: 100%;
      @include fz_vw(16);
      line-height: 1.8;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pr, 30);
      grid-area: 2 / 1 / 3 / 2;

      @include mq(tab) {
        @include fz_vw(24);
      }

      @include mq(md) {
        max-width: 100%;
        @include fz_vw(30);
        @include props(pr, 0);
				grid-area: 3 / 1 / 4 / 2;
			}
    }
    .img{
      @include props(wd, 757);
      grid-area: 1 / 2 / 3 / 2;

      img{
        width: 100%;
        transition: none;
      }

      @include mq(md) {
        width: 100%;
        @include props(mb, 25, 750);
        @include props(ml, 0);
				grid-area: 2 / 1 / 3 / 2;
			}
    }
    .img02{
      @include props(mt, 60);

      @include mq(md) {
        @include props(mt, 80, 750);
			}
    }
  }
}
@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;

// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;

$twitter: #1da1f2;
$facebook: #3b5998;

// 基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP&:400,500,700&display=swap&subset=japanese");
$base-font:"Noto Serif JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
$base-gothic-font:"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

//Inconsolata
@import url("https://fonts.googleapis.com/css?family=Inconsolata&display=swap");
$Inconsolata:'Inconsolata';

// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 767px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1366px)",
) !default;

$properties: ('pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'p': 'padding',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'm': 'margin',
  'wd': 'width',
  'he': 'height',
  'top': 'top',
  'left': 'left',
  'bottom': 'bottom',
  'right': 'right',
) !default;

/* ================================================================================
importファイル
================================================================================ */

// @mixin定義
@import "foundation/mixin";

// @extend定義
@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";

/************************************
トップページ
***********************************/

// トップページ
@import "page/index";

// topics
@import "page/topics";

// concept
@import "page/concept";

// ayurveda
@import "page/ayurveda";

// contact
@import "page/contact";

//facility
@import "page/facility";

//schedule
@import "page/schedule";

//ドイツ語
// トップページ
@import "page/index-de";

// topics
@import "page/topics-de";

// concept
@import "page/concept-de";

// ayurveda
@import "page/ayurveda-de";

// contact
@import "page/contact-de";

//facility
@import "page/facility-de";

//schedule
@import "page/schedule-de";

//英語
// トップページ
@import "page/index-en";

// topics
@import "page/topics-en";

// concept
@import "page/concept-en";

// ayurveda
@import "page/ayurveda-en";

// contact
@import "page/contact-en";

//facility
@import "page/facility-en";

//schedule
@import "page/schedule-en";
@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#de{
  #ayurveda {
  @include props(mb, 250);

  @include mq(md) {
    @include props(mb, 250, 750);
  }

  .ayurvedaCon{
    max-width: 1366px;
    margin: 0 auto;
    @include props(mb, 220);

    @include mq(md) {
      @include props(mb, 220, 750);
    }

    .container{
      max-width: 100%;
      width: 100%;
      @include props(pr, 50);
      @include props(pl, 155);
      margin: 0 auto;

      @include mq(md) {
        padding: 0 20px;
			}
    }

    .ayurvedaConTxtBox{
      @include props(wd, 744);
      @include props(p, 80);
      @include props(mb, 80);
  
      @include mq(md) {
        width: 100%;
        @include props(pt, 80, 750);
        @include props(pb, 80, 750);
        @include props(pl, 0, 750);
        @include props(pr, 0, 750);
        @include props(mb, 80, 750);
      }
    }

    .ayurvedaConTxt{
      @include fz_vw(18);
      font-weight: normal;
      line-height: 2;
      letter-spacing: normal;

      @include mq(tab) {
        @include fz_vw(26);
      }

      @include mq(md) {
        @include fz_vw(32);
      }
    }

    .ayurvedaConTit{
      @include fz_vw(24);
      font-weight: 700;
      line-height: 2;
      letter-spacing: normal;
      @include props(pb, 40);

      @include mq(tab) {
        @include fz_vw(34);
      }

      @include mq(md) {
        @include fz_vw(36);
          @include props(pb, 40, 750);
      }
    }

    .ayurvedaSubCon{
      max-width: 800px;
      margin: 0 auto;
      @include props(mb, 120);

      .img{
        @include props(mb, 40);
      }

      @include mq(md) {
        @include props(mb, 120, 750);

        .img{
          @include props(mb, 50, 750);
        }
      }
    }

    .ayurvedaSubConTit{
      font-family: $base-gothic-font;
      @include fz_vw(24);
      line-height: 1.6;
      font-weight: 500;
      letter-spacing: normal;
      @include props(pr, 20);
      @include props(pl, 20);
      @include props(mb, 30);

      @include mq(tab) {
        @include fz_vw(34);
      }

      @include mq(md) {
        @include fz_vw(36);
        @include props(pl, 0, 750);
        @include props(pr, 0, 750);
        @include props(mb, 40, 750);
			}
    }

    .ayurvedaSubConTxt{
      @include fz_vw(18);
      line-height: 2;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pr, 20);
      @include props(pl, 20);
      @include props(mb, 40);

      cite{
        &::before{
          content: "ー";
          @include props(pr, 5);
        }
      }

      @include mq(tab) {
        @include fz_vw(26);
      }

      @include mq(md) {
        @include fz_vw(32);
        @include props(pl, 0, 750);
        @include props(pr, 0, 750);
        @include props(mb, 60, 750);

        cite{
          &::before{
            @include props(pr, 10, 750);
          }
        }
			}
    }

    .ayurvedaSubConList{
      @include fz_vw(18);
      line-height: 2;
      font-weight: 400;
      letter-spacing: normal;
      @include props(pr, 20);
      @include props(pl, 20);
      @include props(mb, 40);

      .list{
        display: flex;
        align-items: baseline;
        @include props(mb, 10);

        &::before{
          content: "・";
        }
      }

      @include mq(tab) {
        @include fz_vw(26);
      }

      @include mq(md) {
        @include fz_vw(32);
        @include props(pl, 0, 750);
        @include props(pr, 0, 750);
        @include props(mb, 60, 750);

        .list{
          @include props(mb, 10, 750);
        }  
			}
    }
  }
  }
}